import React, { useState, useEffect } from "react";
import thePolak from "../../icons/thePolak.png";
import HeroGradient from "../../icons/hero-gradient.svg";
import cn from "classnames";

const AnimatedText = ({ text, speed }: { text: string; speed: number }) => {
  const [displayedText, setDisplayedText] = useState("");
  const [index, setIndex] = useState(0);

  useEffect(() => {
    if (index < text.length) {
      const timeoutId = setTimeout(() => {
        setDisplayedText((prev) => prev + text[index]);
        setIndex((prev) => prev + 1);
      }, speed);
      return () => clearTimeout(timeoutId);
    }
  }, [index, text, speed]);

  return <span style={{ whiteSpace: "pre-line" }}>{displayedText}</span>;
};

export function Hero({ id }: { id: string }) {
  const [showText, setShowText] = useState(false);
  const animatedText = "\nwith Poland's finest \nWeb3 & AI Engineers";

  return (
    <>
      <div
        className="mt-20 flex h-[90vh] w-full flex-col items-center justify-center px-12"
        id={id}
      >
        <div className="flex w-full flex-col items-center justify-center text-center">
          <div className="min-h-[230px] text-5xl uppercase text-white md:text-6xl xl:text-7xl ">
            <strong>Build</strong> your next product
            <strong>
              <AnimatedText text={animatedText} speed={50} />
            </strong>
          </div>
        </div>

        <div className="flex w-full justify-between py-2">
          <span />
          <div className="flex flex-col-reverse items-center justify-evenly gap-16 py-8 md:gap-12 lg:w-[65%] lg:flex-row lg:gap-0">
            <a
              href="https://calendly.com/thepolacydevs"
              target="_blank"
              rel="noreferrer"
              className="relative z-10 h-fit w-fit text-nowrap bg-red px-6 py-2.5 text-xl font-semibold text-white shadow-[5px_5px_0px_white] transition-all hover:translate-x-[5px] hover:translate-y-[5px] hover:rounded-lg hover:shadow-none lg:text-3xl"
            >
              Get in touch!
              <a
                href="https://calendly.com/thepolacydevs"
                target="_blank"
                rel="noreferrer"
                onMouseEnter={() => setShowText(true)}
                onMouseLeave={() => setShowText(false)}
              >
                <img
                  src={thePolak}
                  alt="thePolak"
                  className="jump h-26 w-26 absolute -left-6 -top-12 -z-[1] hidden cursor-pointer duration-300 md:block lg:-top-14"
                />
              </a>
            </a>
            <p
              className={cn(
                "text-lg italic text-white duration-300 max-lg:text-center lg:max-w-[375px] lg:text-clip xl:max-w-[475px]",
                showText
                  ? "pointer-events-none -z-[9999] cursor-help text-white"
                  : "text-white md:text-transparent",
              )}
            >
              We're not your typical software house.We're a clandestine group of
              tech maestros, the Degens of the digital frontier.You won't find
              us in the mainstream.We thrive in the shadows, creating the
              extraordinary in a world drowned in ordinary.
            </p>
          </div>
        </div>

        <img
          src={HeroGradient}
          alt="hero-gradient"
          className="absolute -z-50 hidden min-h-full min-w-full opacity-75 lg:block xl:left-0 xl:top-28"
        />

        <div
          className="max-w-screen absolute left-[-80px] top-[-80px] -z-[9999] flex h-full w-screen rounded-full opacity-50 lg:hidden"
          style={{
            background:
              "radial-gradient(50% 50% at 50% 50%, #D4213D 0%, rgba(217, 217, 217, 0.00) 100%, rgba(212, 33, 61, 0.00) 100%)",
          }}
        />
      </div>
    </>
  );
}

export default Hero;
