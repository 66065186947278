import React from "react";

export function WeHuntLogo() {
  return (
    <svg
      width="124"
      height="25"
      viewBox="0 0 124 25"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M30.84 4.9441C30.7605 4.80106 30.6413 4.65007 30.4824 4.49113C30.3234 4.33219 30.244 4.20505 30.244 4.10968C30.244 4.01432 30.2678 3.9428 30.3155 3.89512C30.3632 3.83155 30.4347 3.79976 30.53 3.79976C31.2294 3.87923 32.0479 3.91896 32.9856 3.91896C33.7803 3.91896 34.4717 3.90307 35.0597 3.87128C35.6478 3.8236 35.9657 3.79976 36.0134 3.79976C36.1405 3.79976 36.2438 3.83155 36.3233 3.89512C36.4028 3.9587 36.4425 4.04611 36.4425 4.15737V4.37193C36.4425 4.51497 36.4743 4.63417 36.5379 4.72954L40.853 13.5505L42.5933 9.44996L40.4477 4.9441C40.3682 4.80106 40.249 4.65007 40.0901 4.49113C39.9312 4.33219 39.8517 4.20505 39.8517 4.10968C39.8517 4.01432 39.8755 3.9428 39.9232 3.89512C39.9709 3.83155 40.0424 3.79976 40.1378 3.79976C40.8371 3.87923 41.6556 3.91896 42.5933 3.91896C43.388 3.91896 44.0794 3.90307 44.6675 3.87128C45.2555 3.8236 45.5734 3.79976 45.6211 3.79976C45.7482 3.79976 45.8515 3.83155 45.931 3.89512C46.0105 3.9587 46.0502 4.04611 46.0502 4.15737V4.37193C46.0502 4.51497 46.082 4.63417 46.1456 4.72954L50.3892 13.4075L54.3467 4.44345C54.3626 4.41166 54.3706 4.35603 54.3706 4.27657C54.3706 4.1971 54.3626 4.13353 54.3467 4.08584C54.3308 4.02227 54.3229 3.97459 54.3229 3.9428C54.3229 3.91102 54.3388 3.87923 54.3706 3.84744C54.4023 3.81565 54.4421 3.79976 54.4898 3.79976C54.8394 3.84744 55.1811 3.87128 55.5149 3.87128C55.8646 3.87128 56.2063 3.84744 56.54 3.79976C56.6354 3.79976 56.6831 3.84744 56.6831 3.9428C56.6831 3.97459 56.6434 4.03816 56.5639 4.13352C56.4844 4.21299 56.4208 4.3163 56.3732 4.44345L49.4832 20.5358H47.9336L43.6185 11.5718L39.8517 20.5358H38.3021L30.84 4.9441Z"
        fill="white"
      />
      <path
        d="M60.432 20.7742C59.3989 20.7742 58.4135 20.5438 57.4758 20.0829C56.5539 19.622 55.791 18.9226 55.1871 17.9849C54.599 17.0313 54.305 15.8631 54.305 14.4803C54.305 12.1917 54.9169 10.4433 56.1407 9.2354C57.3645 8.02748 59.0334 7.42352 61.1472 7.42352C62.0055 7.42352 62.8002 7.62219 63.5313 8.01953C64.2783 8.40098 64.8663 8.91752 65.2955 9.56916C65.7405 10.2049 65.963 10.8724 65.963 11.5718C65.963 12.5731 65.5736 13.3519 64.7948 13.9082C64.0319 14.4644 63.0624 14.7426 61.8863 14.7426C61.3141 14.7426 60.7658 14.6949 60.2413 14.5995C59.7168 14.4883 59.3036 14.385 59.0016 14.2896C59.1446 15.5929 59.542 16.5863 60.1936 17.2697C60.8611 17.9372 61.6717 18.271 62.6253 18.271C63.4836 18.271 64.318 17.9531 65.1286 17.3174C65.208 17.2697 65.2716 17.2459 65.3193 17.2459C65.4147 17.2459 65.4941 17.2776 65.5577 17.3412C65.6213 17.4048 65.6531 17.4922 65.6531 17.6035C65.6531 18.0326 65.4306 18.4935 64.9855 18.9862C64.5564 19.4789 63.9445 19.9001 63.1498 20.2498C62.371 20.5994 61.4651 20.7742 60.432 20.7742ZM58.9539 13.3837C59.6373 13.3996 60.2254 13.2327 60.7181 12.883C61.2267 12.5333 61.6081 12.0883 61.8624 11.5479C62.1167 10.9916 62.2439 10.4513 62.2439 9.92677C62.2439 9.51354 62.1644 9.17182 62.0055 8.90163C61.8465 8.63144 61.5922 8.49634 61.2426 8.49634C60.7658 8.49634 60.3525 8.76653 60.0029 9.30692C59.6691 9.83141 59.4069 10.483 59.2161 11.2618C59.0413 12.0247 58.9539 12.732 58.9539 13.3837Z"
        fill="white"
      />
      <path
        d="M82.9922 18.6286C82.9922 18.867 83.0319 19.0816 83.1114 19.2723C83.1909 19.4471 83.2942 19.6299 83.4213 19.8206C83.5644 20.0114 83.6359 20.1464 83.6359 20.2259C83.6359 20.3054 83.6121 20.3769 83.5644 20.4405C83.5167 20.5041 83.4531 20.5358 83.3737 20.5358H77.5804C77.5009 20.5358 77.4374 20.5041 77.3897 20.4405C77.342 20.3769 77.3182 20.3054 77.3182 20.2259C77.3182 20.1464 77.3817 20.0114 77.5089 19.8206C77.636 19.6299 77.7393 19.4471 77.8188 19.2723C77.9142 19.0975 77.9619 18.8829 77.9619 18.6286V12.1439H72.2878V18.6286C72.2878 18.867 72.3275 19.0816 72.407 19.2723C72.4865 19.4471 72.5898 19.6299 72.7169 19.8206C72.86 20.0114 72.9315 20.1464 72.9315 20.2259C72.9315 20.3054 72.9077 20.3769 72.86 20.4405C72.8123 20.5041 72.7487 20.5358 72.6693 20.5358H66.876C66.7965 20.5358 66.733 20.5041 66.6853 20.4405C66.6376 20.3769 66.6138 20.3054 66.6138 20.2259C66.6138 20.1464 66.6773 20.0114 66.8045 19.8206C66.9316 19.6299 67.035 19.4471 67.1144 19.2723C67.2098 19.0975 67.2575 18.8829 67.2575 18.6286V6.44605C67.2575 6.00103 67.035 5.60369 66.5899 5.25403C66.4946 5.17456 66.4469 5.0792 66.4469 4.96794C66.4469 4.88847 66.4707 4.8249 66.5184 4.77722C66.5661 4.71364 66.6297 4.68186 66.7091 4.68186C67.6151 4.68186 68.5528 4.58649 69.5223 4.39577C70.4918 4.20505 71.2229 3.93485 71.7156 3.58519C71.7792 3.53751 71.8507 3.51367 71.9302 3.51367C72.0097 3.51367 72.0812 3.54546 72.1448 3.60903C72.2242 3.67261 72.2719 3.75208 72.2878 3.84744V10.2844H77.9619V6.44605C77.9619 6.00103 77.7393 5.60369 77.2943 5.25403C77.199 5.17456 77.1513 5.0792 77.1513 4.96794C77.1513 4.88847 77.1751 4.8249 77.2228 4.77722C77.2705 4.71364 77.3341 4.68186 77.4135 4.68186C78.3195 4.68186 79.2572 4.58649 80.2267 4.39577C81.1962 4.20505 81.9273 3.93485 82.42 3.58519C82.4995 3.53751 82.571 3.51367 82.6346 3.51367C82.7141 3.51367 82.7856 3.54546 82.8492 3.60903C82.9286 3.67261 82.9763 3.75208 82.9922 3.84744V18.6286Z"
        fill="white"
      />
      <path
        d="M97.7966 18.6524C97.7966 18.8749 97.8363 19.0736 97.9158 19.2485C97.9953 19.4233 98.1065 19.614 98.2496 19.8206C98.3926 20.0272 98.4641 20.1623 98.4641 20.2259C98.4641 20.3213 98.4403 20.4007 98.3926 20.4643C98.3449 20.512 98.2734 20.5358 98.178 20.5358H92.7901V18.8432C92.361 19.4789 91.8206 19.9875 91.1689 20.369C90.5173 20.7345 89.8418 20.9173 89.1425 20.9173C88.3796 20.9173 87.6564 20.7266 86.973 20.3451C86.2896 19.9637 85.7333 19.4312 85.3042 18.7478C84.875 18.0485 84.6605 17.2538 84.6605 16.3637V10.4989C84.6605 10.1811 84.6128 9.95856 84.5174 9.83141C84.4221 9.68837 84.2552 9.51354 84.0168 9.30692C83.9214 9.22745 83.8737 9.13209 83.8737 9.02083C83.8737 8.94137 83.8976 8.87779 83.9453 8.83011C83.9929 8.78243 84.0565 8.75859 84.136 8.75859C85.1532 8.75859 86.0273 8.66323 86.7584 8.4725C87.5054 8.26588 88.2048 8.02747 88.8564 7.75728C89.1425 7.64603 89.3014 7.5904 89.3332 7.5904C89.4286 7.5904 89.5081 7.62219 89.5716 7.68576C89.6511 7.73344 89.6908 7.80497 89.6908 7.90033V16.0777C89.6908 16.7134 89.8816 17.2141 90.263 17.5796C90.6603 17.9293 91.1451 18.1041 91.7173 18.1041C92.0987 18.1041 92.4563 18.0246 92.7901 17.8657V10.4989C92.7901 10.1811 92.7424 9.95856 92.647 9.83141C92.5517 9.68837 92.3848 9.51354 92.1464 9.30692C92.051 9.22745 92.0034 9.13209 92.0034 9.02083C92.0034 8.94137 92.0272 8.87779 92.0749 8.83011C92.1226 8.78243 92.1861 8.75859 92.2656 8.75859C93.2828 8.75859 94.1569 8.66323 94.8881 8.4725C95.6351 8.26588 96.3344 8.02747 96.986 7.75728C97.2721 7.64603 97.431 7.5904 97.4628 7.5904C97.5582 7.5904 97.6377 7.62219 97.7012 7.68576C97.7807 7.73344 97.8204 7.80497 97.8204 7.90033V10.4989L97.7966 18.6524Z"
        fill="white"
      />
      <path
        d="M113.087 18.6286C113.087 18.867 113.126 19.0816 113.206 19.2723C113.285 19.4471 113.389 19.6299 113.516 19.8206C113.659 20.0114 113.73 20.1464 113.73 20.2259C113.73 20.3054 113.706 20.3769 113.659 20.4405C113.611 20.5041 113.547 20.5358 113.468 20.5358H107.675C107.595 20.5358 107.532 20.5041 107.484 20.4405C107.436 20.3769 107.413 20.3054 107.413 20.2259C107.413 20.1464 107.476 20.0114 107.603 19.8206C107.73 19.6299 107.834 19.4471 107.913 19.2723C108.009 19.0975 108.056 18.8829 108.056 18.6286V12.0486C108.056 11.4446 107.873 10.9758 107.508 10.642C107.158 10.2923 106.737 10.1175 106.244 10.1175C105.704 10.1175 105.211 10.3241 104.766 10.7374V18.6286C104.766 18.867 104.806 19.0816 104.885 19.2723C104.965 19.4471 105.068 19.6299 105.195 19.8206C105.338 20.0114 105.41 20.1464 105.41 20.2259C105.41 20.3054 105.386 20.3769 105.338 20.4405C105.291 20.5041 105.227 20.5358 105.148 20.5358H99.3544C99.275 20.5358 99.2114 20.5041 99.1637 20.4405C99.116 20.3769 99.0922 20.3054 99.0922 20.2259C99.0922 20.1464 99.1558 20.0114 99.2829 19.8206C99.4101 19.6299 99.5134 19.4471 99.5928 19.2723C99.6882 19.0975 99.7359 18.8829 99.7359 18.6286V10.4989C99.7359 10.1811 99.6882 9.95856 99.5928 9.83141C99.4975 9.68837 99.3306 9.51354 99.0922 9.30692C98.9968 9.22745 98.9491 9.13209 98.9491 9.02083C98.9491 8.94137 98.973 8.87779 99.0207 8.83011C99.0683 8.78243 99.1319 8.75859 99.2114 8.75859C100.229 8.75859 101.103 8.66323 101.834 8.4725C102.581 8.26588 103.28 8.02747 103.932 7.75728C104.218 7.64603 104.377 7.5904 104.409 7.5904C104.504 7.5904 104.583 7.62219 104.647 7.68576C104.726 7.73344 104.766 7.80497 104.766 7.90033V9.52148C105.227 8.85395 105.799 8.3374 106.483 7.97185C107.182 7.5904 107.921 7.39968 108.7 7.39968C109.447 7.39968 110.154 7.5904 110.822 7.97185C111.505 8.35329 112.053 8.87779 112.467 9.54533C112.88 10.2129 113.087 10.9519 113.087 11.7625V18.6286Z"
        fill="white"
      />
      <path
        d="M118.698 20.7027C117.522 20.7027 116.577 20.2815 115.861 19.4392C115.146 18.5968 114.789 17.4525 114.789 16.0061L114.765 9.3546C114.765 8.90958 114.542 8.51224 114.097 8.16257C114.018 8.099 113.978 8.00364 113.978 7.87649C113.978 7.68576 114.057 7.5904 114.216 7.5904C115.615 7.5904 116.696 7.23279 117.459 6.51758C118.237 5.78647 118.826 5.02357 119.223 4.22889C119.271 4.11763 119.358 4.062 119.485 4.062C119.565 4.062 119.628 4.08584 119.676 4.13352C119.739 4.1812 119.779 4.24478 119.795 4.32425V7.5904H122.966V8.99699H119.795V16.8882C119.795 17.3809 119.954 17.7465 120.272 17.9849C120.606 18.2233 121.011 18.3425 121.488 18.3425C121.79 18.3425 122.068 18.3028 122.322 18.2233C122.592 18.1438 122.807 18.0405 122.966 17.9134L123.157 18.1756C122.155 19.8604 120.669 20.7027 118.698 20.7027Z"
        fill="white"
      />
      <path
        d="M118.993 9.47021L118.993 7.02621L123.117 7.02621L123.117 9.47021L118.993 9.47021Z"
        fill="white"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M11.9342 13.27H9.49901C8.15412 13.27 7.06386 14.3602 7.06386 15.7051C7.06386 17.05 8.15411 18.1403 9.49901 18.1403C10.8439 18.1403 11.9342 17.05 11.9342 15.7051V13.27ZM9.49901 12.6221C7.79629 12.6221 6.41595 14.0024 6.41595 15.7051C6.41595 17.4079 7.79629 18.7882 9.49901 18.7882C11.2017 18.7882 12.5821 17.4079 12.5821 15.7051V12.6221H9.49901Z"
        fill="white"
      />
      <path
        d="M6.11023 9.19282C6.11023 10.8955 7.49056 12.2759 9.19329 12.2759H12.2763V9.19282C12.2763 7.4901 10.896 6.10976 9.19329 6.10976C7.49056 6.10976 6.11023 7.4901 6.11023 9.19282Z"
        fill="white"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M11.6284 11.628H9.19329C7.84839 11.628 6.75814 10.5377 6.75814 9.19282C6.75814 7.84792 7.84839 6.75767 9.19329 6.75767C10.5382 6.75767 11.6284 7.84792 11.6284 9.19282V11.628ZM9.19329 12.2759C7.49056 12.2759 6.11023 10.8955 6.11023 9.19282C6.11023 7.4901 7.49056 6.10976 9.19329 6.10976C10.896 6.10976 12.2763 7.4901 12.2763 9.19282V12.2759H9.19329Z"
        fill="white"
      />
      <path
        d="M19.5521 15.7051C19.5521 14.0024 18.1717 12.6221 16.469 12.6221H13.3859V15.7051C13.3859 17.4079 14.7663 18.7882 16.469 18.7882C18.1717 18.7882 19.5521 17.4079 19.5521 15.7051Z"
        fill="white"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M14.0339 13.27H16.469C17.8139 13.27 18.9042 14.3602 18.9042 15.7051C18.9042 17.05 17.8139 18.1403 16.469 18.1403C15.1241 18.1403 14.0339 17.05 14.0339 15.7051V13.27ZM16.469 12.6221C18.1717 12.6221 19.5521 14.0024 19.5521 15.7051C19.5521 17.4079 18.1717 18.7882 16.469 18.7882C14.7663 18.7882 13.3859 17.4079 13.3859 15.7051V12.6221H16.469Z"
        fill="white"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M14.0339 11.1695H16.469C17.8139 11.1695 18.9042 10.0792 18.9042 8.73433C18.9042 7.38943 17.8139 6.29917 16.469 6.29917C15.1241 6.29917 14.0339 7.38943 14.0339 8.73433V11.1695ZM16.469 11.8174C18.1717 11.8174 19.5521 10.4371 19.5521 8.73433C19.5521 7.0316 18.1717 5.65127 16.469 5.65127C14.7663 5.65127 13.3859 7.0316 13.3859 8.73433V11.8174H16.469Z"
        fill="white"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M12.9837 23.7921C19.3748 23.7921 24.5558 18.6111 24.5558 12.22C24.5558 5.82891 19.3748 0.647906 12.9837 0.647906C6.59265 0.647906 1.41164 5.82891 1.41164 12.22C1.41164 18.6111 6.59265 23.7921 12.9837 23.7921ZM12.9837 24.44C19.7327 24.44 25.2037 18.9689 25.2037 12.22C25.2037 5.47108 19.7327 0 12.9837 0C6.23482 0 0.763733 5.47108 0.763733 12.22C0.763733 18.9689 6.23482 24.44 12.9837 24.44Z"
        fill="white"
      />
      <path
        d="M3.055 6.11002C3.055 6.95364 2.37112 7.63752 1.5275 7.63752C0.683885 7.63752 0 6.95364 0 6.11002C0 5.26641 0.683885 4.58252 1.5275 4.58252C2.37112 4.58252 3.055 5.26641 3.055 6.11002Z"
        fill="white"
      />
    </svg>
  );
}

export default WeHuntLogo;
