import React from "react";
import "./index.css";
import Navbar from "./components/Navbar/Navbar";
import Hero from "./components/Hero/Hero";
import Footer from "./components/Footer/Footer";
import Clients from "./components/Clients/Clients";
import Services from "./components/Services/Services";
import CallToActionSection from "./components/CallToActionSection/CallToActionSection";
import ThePolacyCollection from "./components/ThePolacyCollection/thePolacyCollection";
import { AboutUs } from "./components/AboutUs/AboutUs";

export default function App() {
  return (
    <div className="flex w-full flex-col items-center gap-y-36 lg:gap-y-32">
      <Navbar />
      <Hero id="Home" />
      <div className="sm:px-8 md:px-16">
        <AboutUs id="About" />
        <Services id="Services" />
      </div>
      <Clients id="Clients" />
      <ThePolacyCollection id="Collection" />
      <CallToActionSection />
      <Footer />
    </div>
  );
}
