import FeatureBox from "../common/FeatureBox";

const thePolacyInfo = [
  {
    label: "2137",
    description: "Number of NFTs minted on ETH",
    isNumber: true,
  },
  {
    label: "865",
    description: "Owners (Unique)",
    isNumber: true,
  },
  {
    label: "107 ETH",
    description: "Total volume",
    isNumber: true,
  },
  {
    label: "0.0845 ETH",
    description: "Floor Price",
    isNumber: true,
  },
];

export function ThePolacyCollection({ id }: { id: string }) {
  return (
    <>
      <div className="flex flex-col px-8 md:px-16" id={id}>
        <div className="flex flex-col gap-y-4 px-4 pb-6">
          <div className="text-xl font-semibold text-red">Our collection</div>
          <div className="text-4xl font-semibold text-white">thePolacy NFT</div>
          <div className="text-lg text-gray-300">
            We've built a collection of 2137 Polish NFTs, charging through the
            Ethereum blockchain like the elite Polish Winged Hussars, sowing
            fear, building glory, and achieving spectacular victories!
          </div>
        </div>
        <div className="flex w-full justify-between gap-x-6 pt-4 xl:pt-11">
          <div className="hidden flex-col gap-y-8 xl:flex">
            {thePolacyInfo.map((info) => (
              <FeatureBox
                label={info.label}
                key={info.label}
                description={info.description}
                isNumber={info.isNumber}
              />
            ))}
          </div>
          <div className="flex px-4 xl:px-0">
            <img
              src="/assets/thePolakos.png"
              alt="the Polacy"
              className="h-full rounded-lg xl:h-[428px] xl:w-full"
            />
          </div>
        </div>
        <div className="flex xl:hidden">
          <div className="grid grid-cols-1 gap-8 pt-8 md:grid-cols-2 lg:grid-cols-4">
            {thePolacyInfo.map((info) => (
              <FeatureBox
                isNumber={info.isNumber}
                label={info.label}
                key={info.label}
                description={info.description}
              />
            ))}
          </div>
        </div>
        <div className="flex px-4 pt-6 xl:pt-0">
          <a
            href="https://nft.thepolacy.pl/"
            target="_blank"
            rel="noreferrer"
            className="relative z-10 h-fit w-fit text-nowrap bg-red px-6 py-2 text-xl font-semibold text-white shadow-[5px_5px_0px_white] transition-all hover:translate-x-[5px] hover:translate-y-[5px] hover:rounded-lg hover:shadow-none lg:text-2xl"
          >
            Join thePolacy
          </a>
        </div>
      </div>
    </>
  );
}

export default ThePolacyCollection;
