import React from 'react';

type Props = {
    icon?: React.ReactNode,
    label: string,
    description: string
    isNumber?: boolean;
}

export function FeatureBox({icon, label, description, isNumber} : Props) {
    return (
        <div className="flex flex-col gap-y-4 max-w-1/4">
            {icon &&  <div className='ml-4'>{icon}</div>}
            <div className='flex flex-col gap-y-2'>
                <div className="flex items-center gap-x-4">
                    <div className={`flex ${isNumber ? ' h-6' : 'h-4'} w-0.5 bg-red`}/>
                    {isNumber ? <div className="font-semibold text-[22px] text-white">{label}</div> : <div className="font-semibold text-white">{label}</div>}
                </div>
                <div className="text-gray-300 ml-[18px]">{description}</div>
            </div>
        </div>
    );
};

export default FeatureBox;
