import React, { useState } from "react";
import MenuIcon from "../../icons/MenuIcon";
import XCloseIcon from "../../icons/XCloseIcon";
import MenuItemComponent, { LinkedinIcon } from "./MenuItem";
import { Link } from "react-scroll";

type MenuItem = {
  label: string;
  href: string;
  target?: string;
  className?: string;
  underlineColor?: string;
  underline?: boolean;
};

const menuItems: MenuItem[] = [
  { label: "About us", href: "About" },
  { label: "Services", href: "Services" },
  { label: "Solutions", href: "Clients" },
  { label: "thePolacy NFT", href: "Collection" },
];

export function Navbar() {
  const [isMenuOpen, setMenuOpen] = useState(false);

  const toggleMenu = () => {
    setMenuOpen(!isMenuOpen);
  };

  return (
    <>
      <nav className="fixed z-50 flex w-full items-center justify-between text-nowrap bg-black/90 px-8 py-6 text-sm duration-300 md:px-12 lg:px-24 lg:text-base">
        <div className="flex items-center gap-6 lg:gap-10">
          <Link to="Home" smooth={true} duration={500}>
            <img
              className="h-10 cursor-pointer transition-transform duration-300 ease-out hover:scale-105"
              src="/assets/polacyDevs_logo.png"
              alt="thePolacy logo"
              onClick={() => {
                if (window.innerWidth < 768) {
                  window.scrollTo({
                    top: 0,
                    behavior: "smooth",
                  });
                }
              }}
            />
          </Link>
          <div className="hidden items-center gap-10 md:flex">
            {menuItems.map((menuItem) => (
              <MenuItemComponent
                key={menuItem.label}
                label={menuItem.label}
                href={menuItem.href}
              />
            ))}
          </div>
        </div>

        <div className="hidden md:flex">
          <LinkedinIcon
            label="LinkedIn"
            href="https://www.linkedin.com/company/thepolacy-devs"
            className="text-lg font-bold hover:text-[#0077b5] lg:text-xl"
            underlineColor="bg-[#0077b5]"
          />
        </div>
        <div className="flex cursor-pointer md:hidden" onClick={toggleMenu}>
          <MenuIcon />
        </div>
        {isMenuOpen && (
          <div
            className="fixed inset-0 z-50 h-full w-screen bg-black bg-opacity-30 blur-3xl"
            onClick={toggleMenu}
          />
        )}
      </nav>
      {isMenuOpen && (
        <div className="fixed inset-0 right-0 top-0 z-50 h-full w-full rounded-tr-2xl bg-black p-6 text-white shadow-md md:hidden">
          <div className="cursor-pointer" onClick={toggleMenu}>
            <XCloseIcon />
          </div>
          <div className="flex flex-col gap-y-6 pt-8">
            {menuItems.map((menuItem) => (
              <MenuItemComponent
                key={menuItem.label}
                label={menuItem.label}
                href={menuItem.href}
                onClick={toggleMenu}
                className="overflow-hidden text-center text-4xl font-bold duration-300 hover:text-[#D4213D]"
                underline={false}
              />
            ))}
          </div>
        </div>
      )}
    </>
  );
}

export default Navbar;
