import React from 'react';

export function DeepSpaceLogo () {
    return (
        <svg width="112" height="29" viewBox="0 0 112 29" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M110.209 0.62207C110.209 4.09748 108.828 7.43054 106.37 9.88799C103.913 12.3455 100.579 13.7262 97.1048 13.7262H70.0003C68.2792 13.7267 66.5751 13.3882 64.9849 12.7298C63.3948 12.0715 61.95 11.1063 60.733 9.88933C59.5161 8.6724 58.5509 7.22761 57.8925 5.63748C57.2342 4.04736 56.8956 2.34309 56.8962 0.62207H55.1042C55.1042 4.09748 53.7236 7.43054 51.2661 9.88799C48.8087 12.3455 45.4756 13.7262 42.0002 13.7262H14.8961C13.1751 13.7267 11.4708 13.3882 9.88071 12.7298C8.29055 12.0715 6.84573 11.1063 5.62879 9.88933C4.41185 8.6724 3.44664 7.22761 2.78831 5.63748C2.12998 4.04736 1.79142 2.34309 1.792 0.62207H0V28.6222H1.792C1.792 25.1468 3.17262 21.8137 5.6301 19.3562C8.08759 16.8987 11.4207 15.5181 14.8961 15.5181H42.0002C45.4752 15.5193 48.8076 16.9003 51.2649 19.3576C53.7221 21.8148 55.103 25.1471 55.1042 28.6222H56.8962C56.8962 25.1468 58.2768 21.8137 60.7344 19.3562C63.1918 16.8987 66.5249 15.5181 70.0003 15.5181H97.1048C100.579 15.5193 103.911 16.9003 106.369 19.3576C108.826 21.8148 110.208 25.1471 110.209 28.6222H112V0.62207H110.209Z" fill="white"/>
        </svg>
    );
};

export default DeepSpaceLogo;
