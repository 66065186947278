import React from 'react';

export function TwitterXLogo () {
    return (
        <svg width="27" height="25" viewBox="0 0 27 25" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M0.668349 0L10.6259 13.3142L0.605469 24.1392H2.86067L11.6335 14.6617L18.7218 24.1392H26.3963L15.8785 10.0761L25.2054 0H22.9502L14.8709 8.72854L8.34287 0H0.668349ZM3.98478 1.66118H7.51048L23.0793 22.4777H19.5537L3.98478 1.66118Z" fill="#A0A0A0"/>
        </svg>
    );
};

export default TwitterXLogo;
