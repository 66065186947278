import React, { useState } from "react";
import CarouselBoxes, { CarouselBoxProps } from "./CarouselBoxes";
import HyksosLogo from "../../icons/hyksosLogo";
import WeHuntLogo from "../../icons/WeHuntLogo";
import DeepSpaceLogo from "../../icons/DeepSpaceLogo";
import DelphyLogo from "../../icons/delphyLogo";
import LinkIcon from "../../icons/LinkIcon";
import Arrow from "../../icons/arrow";
import SolanaMatcher from "../../icons/solanaMatcher";
import MusicFund from "../../icons/musicFund";
import thePolki from "../../icons/thepolkiLogo.png";
import cn from "classnames";

export function Clients({ id }: { id: string }) {
  const [selectedBoxIndex, setSelectedBoxIndex] = useState<number>(0);
  const [isHovered, setIsHovered] = useState<boolean>(false);

  const handleBoxClick = (index: number) => {
    setSelectedBoxIndex(index);
  };
  //
  const boxesInfo: CarouselBoxProps[] = [
    {
      label: "WeHunt",
      link: "https://wehunt.ai/",
      image: "/assets/weHuntProduct.png",
      color: "#fff",
      index: 0,
      icon: <WeHuntLogo />,
      description:
        "Wehunt transforms job search, empowering candidates with LLM-powered algorithms. Our mission? To free up time, reduce job hunt stress, and prioritize personal growth and family moments. Wehunt is your personalized job search assistant, helping you discover fitting positions and cultivate your professional profile effortlessly. Our fine-tuned LLM models match candidates with the best opportunities, proactively applying on their behalf. Plus, we continuously update profiles with data from LinkedIn, Behance, GitHub, and more, ensuring a tailored experience.",
    },
    {
      label: "ThePolki Bitcoin NFT",
      link: "https://thepolki.xyz",
      image: "/assets/thepolki.png",
      color: "#0184FF",
      index: 1,
      icon: <img src={thePolki} alt="ThePolki Logo" />,
      description: `thePolki Ordinals is an avant-garde collection of 777 pixel art digital images, minted on the Bitcoin blockchain. The collection humorously portrays images of Polish women, reflecting aspects of Polish reality. Through thePolki Ordinals, the creators of the thePolacy project invite audiences to engage with the world of cryptocurrencies and blockchain technology.`,
    },

    {
      label: "Delphy Records",
      link: "https://delphy.pl/",
      image: "/assets/delphyProduct.png",
      color: "#6710e1",
      index: 2,
      icon: <DelphyLogo />,
      description:
        "At the beginning of every artist's musical journey, numerous questions arise. How to create a song from scratch? Who is a good music producer and where to find one? Which recording studio to choose? Lastly, how to release an album, promote music, and gain new followers on Instagram and TikTok? As the Delphy Records label team, we have already answered these questions and done the work for you.tutaj bedzie nasz opis",
    },

    {
      label: "Solana Matcher",
      link: "",
      image: "/assets/solanaMatcher.jpg",
      color: "#8D33FF",
      index: 3,
      icon: <SolanaMatcher />,
      description:
        "Solana Matcher is a platform that connects businesses, founders, and developers within the Solana ecosystem. Using advanced LLM algorithms, it simplifies the process of finding the right team for projects by matching skillsets and experience. With a focus on community involvement, Solana Matcher rewards active participants.  Businesses can request matches using SOL payments, ensuring fair compensation for contributors using Treasury.",
    },

    {
      label: "ICP Music Fund DAO",
      link: "",
      image: "/assets/icp.png",
      color: "#36FF33",
      index: 4,
      icon: <MusicFund />,
      description: `ICP Music Fund aims to improve the music industry by establishing a decentralized music
      distribution protocol on the Internet Computer Protocol network. Our vision is to create a
      transparent and artist-centric ecosystem where musicians are fairly compensated and
      community-driven curation thrives.`,
    },

    {
      label: "Hyksos Finance",
      link: "https://hyksos.fi/",
      image: "/assets/hyksosProduct.png",
      color: "#9A1AA5",
      index: 5,
      icon: <HyksosLogo />,
      description:
        "Hyksos gives users the possibility to borrow ERC-20 tokens against NFT collateral that demonstrates underlying passive yield in form of ERC-20 tokens. By using eligible NFTs as collateral to get a loan (like popular NFT projects, such as CyberKongz or EtherOrcs) Borrowers can experience the power of instant liquidity, while Lenders earn great rewards - by harnessing tokens which are otherwise not in use - boosted with an auto-compounding mechanism.",
    },

    {
      label: "deep space",
      link: "https://deepspace.lol/",
      image: "/assets/deepSpaceProduct.png",
      color: "#B78EF6",
      index: 6,
      icon: <DeepSpaceLogo />,
      description:
        "Chill out and focus. Our client’s swamped, so here’s the short version.",
    },
  ];

  const selectedBox = boxesInfo[selectedBoxIndex];

  function ProjectDescriptionDesktop() {
    return (
      <div className="hidden h-auto flex-col gap-y-6 px-16 xl:flex xl:h-[360px]">
        <div className="flex flex-col gap-y-6">
          <div className="flex items-center gap-x-4">
            <div
              className="flex h-4 w-0.5"
              style={{ backgroundColor: selectedBox.color }}
            />
            <div
              className="flex text-xl font-semibold"
              style={{ color: selectedBox.color }}
            >
              {selectedBox.label}
            </div>
          </div>
          <div className="flex w-[500px] pl-4 text-lg text-gray-300">
            {selectedBox.description}
          </div>
        </div>
      </div>
    );
  }

  function MobileBoxes({
    selectedBoxIndex,
    boxesInfo,
  }: {
    selectedBoxIndex: number;
    boxesInfo: CarouselBoxProps[];
  }) {
    return (
      <div className="flex w-full justify-center gap-x-4">
        {boxesInfo.map((box) => (
          <div
            key={box.index}
            onClick={() => handleBoxClick(box.index)}
            className={`flex h-[2px] w-[25px] cursor-pointer ${
              selectedBoxIndex === box.index ? "opacity-100" : "opacity-20"
            }
             ${selectedBoxIndex === box.index ? "bg-red" : "bg-gray-300"}`}
          />
        ))}
      </div>
    );
  }

  return (
    <>
      <div className="flex flex-col gap-y-12 px-12 md:px-16" id={id}>
        <div className="flex flex-col gap-y-4 ">
          <div className="text-xl font-semibold text-red">Our products</div>
          <div className="text-4xl font-semibold text-white">
            Examples of our expertise
          </div>
          <p className="w-full text-lg text-gray-300 md:w-[65%]">
            Welcome to our world of Tech Alchemy, where we blend the power of
            Blockchain with endless possibilities. No project managers or Scrum
            masters here – just pure code and good vibes. Our projects break
            free from web2 boundaries, seamlessly spanning chains to craft
            innovative solutions.
          </p>
        </div>
        <div className="flex flex-col gap-y-12">
          <div className="relative flex w-full justify-between gap-x-12">
            <img
              src="/assets/thePolak.png"
              alt="thePolak"
              className="absolute right-0 top-[-290px] hidden w-[320px] md:flex"
            />

            <ProjectDescriptionDesktop />
            <div
              className="relative flex rounded-lg px-4 lg:px-0"
              onMouseEnter={() => setIsHovered(true)}
              onMouseLeave={() => setIsHovered(false)}
            >
              {isHovered && selectedBox.link !== "" && (
                <a
                  href={selectedBox.link}
                  target="_blank"
                  rel="noopener noreferrer"
                  className="hidden md:flex"
                >
                  <div className="absolute left-0 top-0 z-50 flex h-full w-full items-center justify-center bg-black bg-opacity-60 text-white transition delay-500 duration-1000 ease-in-out">
                    <LinkIcon />
                  </div>
                </a>
              )}
              <img
                src={selectedBox.image}
                alt="Selected Box"
                className={cn(
                  "relative rounded-lg object-cover",
                  selectedBox.label === "ICP Music Fund DAO" &&
                    "h-[42rem] w-[350rem]",
                )}
              />
              <div
                className={`${
                  selectedBox.index === 0 ? "hidden" : "flex"
                } absolute left-2 top-1/2 flex h-[44px] w-[44px] -translate-x-1/2 -translate-y-1/2 transform cursor-pointer items-center justify-center md:hidden`}
                onClick={() => setSelectedBoxIndex(selectedBox.index - 1)}
              >
                <Arrow />
              </div>
              <div
                className={`${
                  selectedBox.index === 3 ? "hidden" : "flex"
                }  absolute right-[-36px] top-1/2 h-[44px] w-[44px] -translate-x-1/2 -translate-y-1/2 transform cursor-pointer items-center justify-center md:hidden`}
                onClick={() => setSelectedBoxIndex(selectedBox.index + 1)}
              >
                <Arrow rotateRight />
              </div>
            </div>
          </div>
          <div className="flex xl:hidden">
            <div className="flex flex-col gap-y-4">
              <div className="flex items-center gap-x-4">
                <div
                  className="flex h-4 w-0.5"
                  style={{ backgroundColor: selectedBox.color }}
                />
                <div
                  className="flex text-xl font-semibold"
                  style={{ color: selectedBox.color }}
                >
                  {selectedBox.label}
                </div>
              </div>
              <div className="flex pl-4 text-gray-300">
                {selectedBox.description}
              </div>
            </div>
          </div>
          <div className="hidden md:flex">
            <CarouselBoxes
              selectedBoxIndex={selectedBoxIndex}
              handleBoxClick={handleBoxClick}
              boxesInfo={boxesInfo}
            />
          </div>
          <div className="flex w-full justify-center md:hidden">
            <MobileBoxes
              selectedBoxIndex={selectedBoxIndex}
              boxesInfo={boxesInfo}
            />
          </div>
        </div>
      </div>
    </>
  );
}

export default Clients;
