import React, { useState } from "react";
import FileCodeIcon from "../../icons/fileCodeIcon";
import BrainIcon from "../../icons/brainIcon";
import BridgeIcon from "../../icons/bridgeIcon";

const serviceInfo = [
  {
    number: "01",
    label: "Complex Blockchain Development",
    icon: <FileCodeIcon />,
    image: "/assets/thePolak-service1.png",
    description:
      "Standing on the leading edge of blockchain innovation, we offer a diverse suite of customized solutions spanning DeFi, NFTs, CBDCs, smart contracts, and more. With deep expertise across EVM, Bitcoin, Solana, Ethereum, Algorand, ICP, and a host of other chains, we’re not just navigating but actively shaping the future of blockchain technology. Our dedication to driving unmatched success for our partners is what propels us forward in this dynamic and ever-expanding ecosystem.",

    infoColumns: [
      {
        index: 0,
        rows: [
          "DeFi Applications",
          "NFT Collections & Marketplaces",
          "Bitcoin Ordinals",
          "Trading applications",
          "CBDC Solution Architecture",
          "Smart Contract Audits",
          "Private Blockchains",
          "Lending Protocols",
        ],
      },
      {
        index: 1,
        rows: [
          "Bridges & Oracles",
          "Stablecoins",
          "Yield Farming",
          "Decentralized Exchanges",
          "Blockchain Indexers",
          "Bots & Trading Automation",
          "Liquid Staking",
          "Real World Assets (RWA)",
        ],
      },
    ],
  },
  {
    number: "02",
    label: "AI-powered applications",
    icon: <BrainIcon />,
    image: "/assets/thePolak-service2.png",
    description:
      "We specialize in AI solutions, seamlessly integrating open-source and proprietary LLM models to solve real-world problems. Our expertise spans a range of services including model development, training, dataset access, infrastructure setup, and optimization. From sentiment analysis to fraud detection, we deliver tailored solutions to meet diverse needs.",

    infoColumns: [
      {
        index: 0,
        rows: [
          "Private Language Model Development",
          "Model Training & Fine-Tuning",
          "Access to Diverse Datasets",
          "AI Infrastructure Setup & Optimizations",
        ],
      },
      {
        index: 1,
        rows: [
          "Sentiment Analysis & Prediction Models",
          "Image Recognition & Object Detection",
          "Natural Language Processing (NLP)",
          "Recommendation Systems",
          "Fraud Detection & Security Solutions",
        ],
      },
    ],
  },
  {
    number: "03",
    label: "Bridging the gaps between web2 and web3",
    icon: <BridgeIcon />,
    image: "/assets/thePolak-service3.png",
    description: `Bridging the gap between the familiar realm of Web 2.0
	and the innovative world of Web 3.0, we provide seamless integration
	solutions. Our approach not only fosters wider adoption of Web 3.0
	technologies but also enhances the user experience
	by leveraging the advantages of both worlds.`,
  },
];

function ServiceBox({
  number,
  label,
  icon,
  isActive,
  onClick,
}: {
  number: string;
  label: string;
  icon: React.ReactNode;
  isActive: boolean;
  onClick: () => void;
}) {
  return (
    <div
      onClick={onClick}
      className={`group flex w-full cursor-pointer flex-col gap-y-3 rounded-lg border-[1.5px] border-red px-5 py-6 duration-300 hover:bg-red/40 lg:w-1/3 ${
        isActive ? "opacity-100" : "opacity-30 hover:opacity-100"
      }`}
    >
      <div className="flex w-full justify-between">
        <div
          className={`${isActive ? "opacity-100" : "opacity-80 group-hover:opacity-100"}`}
        >
          {icon}
        </div>
        <div
          className={`${
            isActive
              ? "text-red opacity-100"
              : "text-[#D1D5DB] opacity-60 group-hover:opacity-100"
          }`}
        >
          {number}
        </div>
      </div>
      <div
        className={`text-[22px] font-semibold text-white ${
          isActive
            ? "text-red opacity-100"
            : "text-[#D1D5DB] opacity-60 group-hover:opacity-100"
        }`}
      >
        {label}
      </div>
    </div>
  );
}

function MobileServiceBox({
  icon,
  number,
  label,
  description,
  image,
  //   techIcons,
  infoColumns,
}: {
  icon: React.ReactNode;
  number: string;
  label: string;
  description: string;
  image: string;
  techIcons?: React.ReactNode[];
  infoColumns?: { index: number; rows: string[] }[];
}) {
  return (
    <div className="flex w-full flex-col gap-y-3 px-5 py-6 ">
      <div className="flex w-full justify-between">
        {icon}
        <div className="text-red">{number}</div>
      </div>
      <div className="flex flex-col items-center justify-center gap-y-4 pt-2">
        <div className="text-[24px] font-semibold text-white">{label}</div>
        <div className="w-full md:w-3/4">
          <img className="rounded-lg" src={image} alt="blockchain dev" />
          <div className="text-pretty py-5 text-gray-300">{description}</div>
        </div>
        {/* <div className="flex pt-4 gap-x-4 flex-wrap gap-y-6">
          {techIcons &&
            techIcons.map((techIcon, index) => (
              <div key={index} className="icon-container">
                {techIcon}
              </div>
            ))}
        </div> */}
        <div className="flex gap-5">
          {infoColumns?.map((column, index) => (
            <div
              key={index}
              className="border-light-white rounded-md border-[1px] p-4 text-white"
            >
              {column.rows.map((row, rowIndex) => (
                <div
                  key={rowIndex}
                  className="border-light-white border-b-[1px] py-2 last:border-b-0"
                >
                  {row}
                </div>
              ))}
            </div>
          ))}
        </div>
      </div>
    </div>
  );
}

export function Services({ id }: { id: string }) {
  const [activeBox, setActiveBox] = useState(0);

  return (
    <>
      <div className="flex w-full flex-col gap-y-6 pb-6 text-white" id={id}>
        <div className="flex flex-col gap-y-4 px-4">
          <div className="text-xl font-semibold text-red">Our services</div>
          <div className="text-4xl font-semibold">End-to-end expertise</div>
          <div className="text-lg text-gray-300">
            thePolacy Devs provides software engineering, design, product
            development, and data science services for the fintech industry. We
            lead with breakthrough web3 and AI technology, ensuring top-tier
            code and security standards with our expert senior dev teams.
          </div>
        </div>
        <div className="hidden gap-x-8 gap-y-8 pt-10 lg:flex lg:flex-row">
          {serviceInfo.map((box, index) => (
            <ServiceBox
              key={index}
              onClick={() => setActiveBox(index)}
              isActive={index === activeBox}
              number={box.number}
              label={box.label}
              icon={box.icon}
            />
          ))}
        </div>
        <div className="flex flex-col gap-y-10 pt-4 lg:hidden">
          {serviceInfo.map((box) => (
            <MobileServiceBox
              icon={box.icon}
              key={box.label}
              number={box.number}
              label={box.label}
              description={box.description}
              image={box.image}
              infoColumns={box.infoColumns}
            />
          ))}
        </div>
        <div className="hidden pt-12 lg:flex lg:flex-row">
          <div className="flex max-w-full items-center gap-x-[64px]">
            <img
              className="h-[387px] w-[387px] rounded-lg "
              src={serviceInfo[activeBox].image}
              alt="Polak usługa"
            />
            <div className="flex flex-col gap-y-6 ">
              <div className="flex items-center gap-x-4">
                <div className="flex h-[2px] w-6 bg-red" />
                <div className="text-[20px] text-[#A0A0A0]">
                  {serviceInfo[activeBox].number}
                </div>
              </div>
              <div className="text-[26px] font-semibold text-white lg:text-[36px]">
                {serviceInfo[activeBox].label}
              </div>
              <div className="text-[14px] text-gray-300">
                {serviceInfo[activeBox].description}
              </div>
              <div className="relative hidden w-full items-stretch gap-x-4 lg:flex lg:flex-row">
                {serviceInfo[activeBox].infoColumns?.map(
                  (column, index, arr) => (
                    <React.Fragment key={index}>
                      {index > 0 && (
                        <div className="mx-2 w-[2px] bg-gray-200"></div>
                      )}
                      <div className="flex-1 p-4 text-white">
                        {column.rows.map((row, rowIndex) => (
                          <div
                            key={rowIndex}
                            className="border-b border-gray-700 py-2"
                          >
                            {row}
                          </div>
                        ))}
                      </div>
                    </React.Fragment>
                  ),
                )}
              </div>
              {/* <div className="flex pt-2 gap-x-4 gap-y-6 flex-wrap">
                {serviceInfo[activeBox].techIcons.map((tech, index) => (
                  <div
                    key={index}
                    className="flex flex-col items-center relative"
                  >
                    <div
                      className="cursor-pointer relative hover:opacity-50"
                      onMouseEnter={() => handleTechIconHover(index)}
                      onMouseLeave={handleTechIconLeave}
                    >
                      {tech.icon}
                    </div>
                    {hoveredTech === index && (
                      <div
                        className="absolute top-[-24px] bg-[#A0A0A0] text-black font-regular py-[1px] px-[4px] rounded-[4px] text-[12px]"
                        style={{ whiteSpace: "nowrap" }}
                      >
                        {tech.label}
                      </div>
                    )}
                  </div>
                ))}
              </div> */}
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default Services;
