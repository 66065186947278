import React from "react";
// import CtaButton from "../common/CtaButton";

export function AboutUs({ id }: { id: string }) {
  return (
    <div
      className="relative flex items-center justify-center bg-cover md:h-full md:w-full md:bg-contain"
      style={{
        backgroundImage: "url(/assets/background-bg.png)",
        backgroundRepeat: "no-repeat",
        backgroundPosition: "center",
      }}
    >
      <>
        <div
          className="z-30 flex flex-col items-center justify-between gap-y-12 md:flex-row"
          id={id}
        >
          <div className="flex w-full flex-col gap-y-4 px-4 pb-6 pt-12 md:w-[40%] md:pt-0">
            <div className="text-xl font-semibold text-red">About us</div>
            <div className="text-4xl font-semibold text-white">
              Code Rebels: Where Limits Cease to Exist
            </div>
            <div className="text-lg text-gray-300">
              In a sea of corporate conformity, we stand as a small team of
              hackers, rewriting the rules of possibility. At thePolacy Devs, we
              don't just build web3 and AI applications; we infiltrate the norm,
              crafting digital masterpieces that the big software houses can
              only dream of.
            </div>
            <div className="flex pt-4">
              {/* <a href='https://calendly.com/iwopietak/thepolacy-development' target="_blank" rel="noreferrer" >
                <CtaButton isBackground/>
              </a> */}
            </div>
          </div>
          <div className="w-full md:flex md:w-[400px] lg:w-[600px]">
            <img src="/assets/thePolacy_about5.png" alt="thePolacy" />
          </div>
        </div>
      </>
    </div>
  );
}
