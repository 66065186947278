import React from "react";

export type CarouselBoxProps = {
  index: number;
  label: string;
  color: string;
  image?: string;
  icon?: React.ReactNode;
  description: string;
  link: string;
};

type CarouselBoxesProps = {
  selectedBoxIndex: number;
  handleBoxClick: (index: number) => void;
  boxesInfo: CarouselBoxProps[];
};

export function CarouselBoxes({
  selectedBoxIndex,
  handleBoxClick,
  boxesInfo,
}: CarouselBoxesProps) {
  return (
    <div className="flex w-full flex-col">
      <div className="relative flex">
        <div className="absolute z-0 h-px w-full border-t border-dashed border-gray-500 opacity-60" />
        {boxesInfo.map((box, index) => (
          <div
            key={index}
            className={`relative flex-grow cursor-pointer duration-300 hover:bg-white/30 ${
              index === boxesInfo.length - 1
                ? ""
                : "border-r border-dashed border-gray-500"
            }`}
            onClick={() => handleBoxClick(box.index)}
            style={{ width: `${100 / boxesInfo.length}%` }}
          >
            {selectedBoxIndex === index && (
              <div className="absolute z-30 h-px w-full border-red opacity-100">
                <div
                  style={{
                    position: "absolute",
                    top: 0,
                    left: 0,
                    width: "100%",
                    height: "100%",
                    backgroundColor: box.color,
                  }}
                />
              </div>
            )}
            <div
              key={index}
              className={`flex h-32 items-center justify-center text-red ${
                selectedBoxIndex === index ? "opacity-100" : "opacity-50"
              }`}
            >
              {box.icon}
            </div>
          </div>
        ))}
      </div>
    </div>
  );
}

export default CarouselBoxes;
