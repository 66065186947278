import { Link } from "react-scroll";

export type IFooterItem = {
  label: string;
  link?: string;
  href: string;
  isExternal?: boolean;
};

export function FooterItem({ label, link, href, isExternal }: IFooterItem) {
  return (
    <div className="cursor-pointer text-gray-300 duration-300 hover:text-red">
      {isExternal ? (
        <a target="_blank" rel="noreferrer" href={link}>
          {label}
        </a>
      ) : (
        <Link to={href} smooth={true} duration={500}>
          {label}
        </Link>
      )}
    </div>
  );
}
export default FooterItem;
