import { useState } from "react";
import cn from "classnames";
import { Link } from "react-scroll";

type MenuItem = {
  label: string;
  href: string;
  className?: string;
  underlineColor?: string;
  underline?: boolean;
};

export const LinkedinIcon = ({
  label,
  href,
  onClick,
  className = "font-regular hover:text-[#D4213D]",
  underlineColor = "bg-[#D4213D]",
  underline = true,
}: MenuItem & { onClick?: () => void }) => {
  const [isHovered, setIsHovered] = useState(false);
  return (
    <a href={href} onClick={onClick} target="_blank" rel="noreferrer">
      <div
        className={cn(
          "font-regular relative cursor-pointer text-white transition-colors duration-300 ease-out hover:scale-105",
          className,
        )}
        onMouseEnter={() => setIsHovered(true)}
        onMouseLeave={() => setIsHovered(false)}
      >
        {label}
        {underline && (
          <span
            className={cn(
              "absolute -bottom-1 -left-2 -right-3 z-20 h-0.5 origin-left rounded-full transition-transform duration-300 ease-out",
              underlineColor,
            )}
            style={{
              transform: isHovered ? "scaleX(1)" : "scaleX(0)",
            }}
          />
        )}
      </div>
    </a>
  );
};

function MenuItemComponent({
  label,
  href,
  onClick,
  className = "font-regular hover:text-[#D4213D]",
  underlineColor = "bg-[#D4213D]",
  underline = true,
}: MenuItem & { onClick?: () => void }) {
  const [isHovered, setIsHovered] = useState(false);
  return (
    <Link to={href} onClick={onClick} smooth={true} duration={500}>
      <div
        className={cn(
          "font-regular relative cursor-pointer text-white transition-colors duration-300 ease-out hover:scale-105",
          className,
        )}
        onMouseEnter={() => setIsHovered(true)}
        onMouseLeave={() => setIsHovered(false)}
      >
        {label}
        {underline && (
          <span
            className={cn(
              "absolute -bottom-1 -left-2 -right-3 z-20 h-0.5 origin-left rounded-full transition-transform duration-300 ease-out",
              underlineColor,
            )}
            style={{
              transform: isHovered ? "scaleX(1)" : "scaleX(0)",
            }}
          />
        )}
      </div>
    </Link>
  );
}

export default MenuItemComponent;
