import React from "react";
import { FooterItem, IFooterItem } from "./FooterItem";
import TwitterXLogo from "../../icons/twitterXLogo";

const footerInfo: IFooterItem[] = [
  { label: "About us", href: "About" },
  { label: "Services", href: "Services" },
  { label: "Solutions", link: "https://twitter.com/home", href: "Clients" },
  { label: "thePolacy", link: "https://twitter.com/home", href: "Collection" },
  {
    label: "Get in touch with us!",
    isExternal: true,
    link: "https://calendly.com/thepolacydevs",
    href: "",
  },
];

export function Footer() {
  const currentYear = new Date().getFullYear();

  return (
    <>
      <div className="flex w-full flex-col items-center gap-6 px-8 pb-12 md:px-16">
        <img
          src="/assets/polacyDevs_logo.png"
          alt="thePolacy logo"
          className="w-[144px]"
        />
        <div className="flex w-full flex-col justify-center sm:flex-row">
          <div className="flex flex-wrap items-center justify-center gap-x-8">
            {footerInfo.map((item) => (
              <FooterItem
                key={item.label}
                label={item.label}
                link={item.link}
                isExternal={item.isExternal}
                href={item.href}
              />
            ))}
            <a
              href="https://twitter.com/thePolacy_"
              target="blank"
              rel="noreferrer"
              className="my-2 hover:opacity-80"
            >
              <TwitterXLogo />
            </a>
          </div>
        </div>
        <div className="text-center">
          <p className="text-[14px] text-gray-300 ">
            ©{currentYear} TheVelopers sp. z o.o.
          </p>
          <p className="pt-1 text-[12px] text-gray-500">
            NIP: 5273105657 REGON: 528202545
          </p>
        </div>
      </div>
    </>
  );
}

export default Footer;
