import React from 'react';

export function Arrow ({rotateRight} : {rotateRight?: boolean}) {
    return (
        <svg width="24" height="41" viewBox="0 0 24 41" fill="none" xmlns="http://www.w3.org/2000/svg" style={{ transform: rotateRight ? 'rotate(-180deg)' : '' }}>
            <path d="M22.6372 37.817C22.8104 37.9901 22.9477 38.1957 23.0414 38.4219C23.1351 38.6481 23.1834 38.8906 23.1834 39.1354C23.1834 39.3803 23.1351 39.6227 23.0414 39.849C22.9477 40.0752 22.8104 40.2807 22.6372 40.4539C22.4641 40.627 22.2586 40.7644 22.0323 40.8581C21.8061 40.9518 21.5636 41 21.3188 41C21.0739 41 20.8315 40.9518 20.6052 40.8581C20.379 40.7644 20.1735 40.627 20.0003 40.4539L1.36491 21.8185C1.19165 21.6454 1.05419 21.4399 0.960412 21.2136C0.866631 20.9874 0.818359 20.7449 0.818359 20.5C0.818359 20.2551 0.866631 20.0126 0.960412 19.7864C1.05419 19.5601 1.19165 19.3546 1.36491 19.1815L20.0003 0.546122C20.35 0.196446 20.8243 -9.74811e-09 21.3188 0C21.8133 9.74811e-09 22.2876 0.196446 22.6372 0.546122C22.9869 0.895798 23.1834 1.37006 23.1834 1.86458C23.1834 2.35909 22.9869 2.83336 22.6372 3.18303L5.31795 20.5L22.6372 37.817Z" fill="white"/>
        </svg>
    );
};

export default Arrow;
