import React from 'react';

export function HyksosLogo() {
    return (
        <svg width="131" height="34" viewBox="0 0 131 34" fill="none" xmlns="http://www.w3.org/2000/svg">
            <g clipPath="url(#clip0_258_2020)">
                <path d="M53.0392 6.17896V25.8118H49.2513V17.4333H40.3472V25.8118H36.5312V6.17896H40.3472V14.1151H49.2513V6.17896H53.0392Z" fill="white"/>
                <path d="M57.0777 28.6323C57.377 28.7213 57.6819 28.7906 57.9901 28.8397C58.2368 28.8815 58.4864 28.9046 58.7369 28.9089C58.9652 28.9111 59.1917 28.8639 59.4006 28.7706C59.6239 28.6605 59.8144 28.4936 59.9532 28.2867C60.1548 27.9886 60.3218 27.6686 60.4514 27.3327C60.617 26.9272 60.7923 26.4202 60.9768 25.8119L55.2524 11.3223H59.0685L62.9953 22.6043L66.4792 11.3223H69.9637L63.88 28.6877C63.5456 29.6795 62.9192 30.547 62.0824 31.1763C61.1696 31.8423 60.0598 32.183 58.9301 32.1442C58.6294 32.1435 58.3291 32.1204 58.0316 32.075C57.7073 32.024 57.3886 31.9454 57.0777 31.84V28.6323Z" fill="white"/>
                <path d="M81.8807 25.8118L77.6776 19.5349L75.6868 21.4981V25.8118H71.9814V5.62598H75.6868V17.8758L81.4937 11.3499H85.4477L80.0555 17.4886L85.8347 25.8118H81.8807Z" fill="white"/>
                <path d="M93.7148 26.0884C92.5021 26.089 91.2973 25.8929 90.1474 25.5077C89.0523 25.1575 88.0371 24.5936 87.1611 23.8486L88.544 21.5258C89.3429 22.106 90.2115 22.5843 91.129 22.9499C91.926 23.2645 92.7748 23.4286 93.6318 23.4338C94.2402 23.4661 94.8454 23.3221 95.374 23.019C95.5756 22.895 95.7412 22.7195 95.8528 22.5105C95.9644 22.3016 96.0189 22.0667 96.0096 21.83C96.0161 21.5874 95.9478 21.3487 95.814 21.1461C95.6807 20.9434 95.4884 20.7865 95.2633 20.6962C94.4754 20.3555 93.6618 20.0782 92.8296 19.8667C91.8896 19.6086 91.0879 19.3598 90.4241 19.1201C89.8526 18.9282 89.312 18.6534 88.8203 18.3043C88.4425 18.0334 88.1381 17.6722 87.9356 17.2536C87.7405 16.8047 87.6459 16.3187 87.6588 15.8295C87.6482 15.1418 87.7945 14.4608 88.0874 13.8386C88.3706 13.2523 88.7816 12.7372 89.2908 12.3316C89.83 11.9042 90.4444 11.5808 91.1017 11.3776C91.8315 11.1506 92.5916 11.0386 93.3555 11.0457C94.3924 11.038 95.4243 11.1967 96.4109 11.5158C97.3768 11.8391 98.2657 12.3567 99.024 13.0367L97.5309 15.2765C96.8777 14.7712 96.1563 14.3615 95.3878 14.0598C94.7296 13.808 94.0317 13.6768 93.3274 13.6726C92.7761 13.6535 92.2305 13.7875 91.7512 14.0598C91.5413 14.1892 91.3711 14.3736 91.259 14.5931C91.1469 14.8124 91.0971 15.0584 91.1151 15.3041C91.1059 15.5261 91.1585 15.7463 91.2673 15.9401C91.3891 16.1256 91.5556 16.2776 91.7512 16.3825C92.0284 16.5359 92.3204 16.661 92.6225 16.7559C92.9819 16.8762 93.4288 17.0006 93.9634 17.1292C94.9589 17.3872 95.8113 17.6453 96.5212 17.9034C97.1402 18.1152 97.7269 18.413 98.2634 18.7882C98.6836 19.0841 99.0249 19.478 99.2588 19.9358C99.4825 20.4255 99.5914 20.9599 99.5771 21.4981C99.6024 22.1435 99.4728 22.7855 99.1984 23.3701C98.9239 23.9548 98.5134 24.465 98.0009 24.8578C96.9502 25.6782 95.5211 26.0884 93.7148 26.0884Z" fill="white"/>
                <path d="M108.951 26.0896C107.86 26.1068 106.778 25.8998 105.771 25.4812C104.869 25.1024 104.055 24.5424 103.379 23.8359C102.725 23.1452 102.213 22.3325 101.872 21.4441C101.172 19.6004 101.172 17.5637 101.872 15.7201C102.213 14.8317 102.725 14.019 103.379 13.3282C104.055 12.6218 104.869 12.0618 105.771 11.6829C106.778 11.2645 107.86 11.0575 108.951 11.0746C110.037 11.0565 111.115 11.2636 112.117 11.6829C113.013 12.0644 113.822 12.6242 114.495 13.3282C115.149 14.0192 115.661 14.8319 116.002 15.7201C116.703 17.5637 116.703 19.6004 116.002 21.4441C115.663 22.33 115.156 23.1421 114.509 23.8359C113.839 24.5441 113.029 25.1044 112.131 25.4812C111.124 25.8998 110.041 26.1069 108.951 26.0896ZM105.162 18.5959C105.155 19.1938 105.253 19.7883 105.453 20.3518C105.633 20.854 105.905 21.3182 106.255 21.7206C106.588 22.1018 106.998 22.4081 107.458 22.6192C107.928 22.8289 108.436 22.9373 108.951 22.9373C109.466 22.9373 109.974 22.8289 110.444 22.6192C110.904 22.408 111.314 22.1017 111.647 21.7206C111.999 21.3145 112.271 20.8453 112.449 20.338C112.648 19.7695 112.747 19.1706 112.739 18.5682C112.745 17.975 112.646 17.3855 112.449 16.8262C112.271 16.3188 111.999 15.8497 111.647 15.4436C111.314 15.0626 110.904 14.7563 110.444 14.5449C109.976 14.3314 109.466 14.2228 108.951 14.2269C108.435 14.2228 107.924 14.3362 107.458 14.5587C106.999 14.7783 106.59 15.0887 106.255 15.4712C105.903 15.8773 105.631 16.3465 105.453 16.8538C105.255 17.4131 105.157 18.0027 105.162 18.5959Z" fill="white"/>
                <path d="M124.769 26.0884C123.556 26.089 122.351 25.8929 121.201 25.5077C120.106 25.1574 119.091 24.5935 118.215 23.8485L119.597 21.5258C120.397 22.1059 121.265 22.5843 122.183 22.9499C122.98 23.2645 123.828 23.4286 124.685 23.4338C125.294 23.4661 125.899 23.3221 126.427 23.019C126.629 22.895 126.795 22.7195 126.907 22.5106C127.018 22.3016 127.073 22.0667 127.063 21.83C127.07 21.5874 127.002 21.3487 126.868 21.1461C126.734 20.9434 126.542 20.7865 126.317 20.6962C125.529 20.3556 124.715 20.0782 123.883 19.8667C122.943 19.6086 122.142 19.3597 121.478 19.1201C120.906 18.9281 120.366 18.6533 119.874 18.3043C119.496 18.0333 119.192 17.6721 118.989 17.2536C118.794 16.8047 118.7 16.3187 118.713 15.8295C118.701 15.1418 118.848 14.4608 119.141 13.8386C119.424 13.2522 119.835 12.7372 120.345 12.3316C120.884 11.9042 121.498 11.5808 122.155 11.3776C122.885 11.1506 123.645 11.0386 124.409 11.0457C125.446 11.038 126.478 11.1967 127.465 11.5158C128.431 11.8391 129.32 12.3567 130.078 13.0367L128.585 15.2765C127.931 14.7712 127.21 14.3615 126.442 14.0598C125.784 13.808 125.086 13.6769 124.382 13.6726C123.83 13.6535 123.285 13.7875 122.805 14.0598C122.596 14.1892 122.426 14.3736 122.313 14.5931C122.201 14.8124 122.151 15.0584 122.169 15.3041C122.16 15.5261 122.213 15.7463 122.322 15.9401C122.443 16.1255 122.61 16.2776 122.805 16.3825C123.082 16.5358 123.374 16.6609 123.676 16.7559C124.036 16.8762 124.483 17.0006 125.017 17.1292C126.013 17.3872 126.865 17.6453 127.575 17.9034C128.194 18.1153 128.781 18.4131 129.317 18.7882C129.737 19.084 130.079 19.4779 130.313 19.9358C130.536 20.4255 130.645 20.9599 130.631 21.4981C130.656 22.1435 130.526 22.7854 130.252 23.3701C129.978 23.9548 129.567 24.465 129.055 24.8578C128.003 25.6782 126.575 26.0884 124.769 26.0884Z" fill="white"/>
                <path d="M29.8901 14.8191L29.8869 14.8159L14.9516 0L0 14.803L13.729 28.4192V33.2113H17.9738V26.6764L6.01521 14.8159L14.9516 5.95612L23.888 14.8191L19.304 19.3654L14.9027 14.997L11.9 17.975L19.304 25.3183L29.8901 14.8191Z" fill="white"/>
            </g>
            <defs>
                <clipPath id="clip0_258_2020">
                    <rect width="131" height="33.2113" fill="white"/>
                </clipPath>
            </defs>
        </svg>
    )
}

export default HyksosLogo;
