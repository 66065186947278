import React from "react";
export function CallToActionSection() {
  return (
    <div
      className="flex h-[460px] w-full flex-col items-center justify-center gap-y-10"
      style={{
        background:
          "linear-gradient(180deg, rgba(212, 33, 61, 0.80) 0%, rgba(212, 33, 61, 0.05) 100%)",
      }}
    >
      <div className="px-4 text-center text-[26px] text-white md:px-0">
        Alright, no beating around the bush. Convinced? Perfect. Now, try to
        catch us!
      </div>
      <div className="flex">
        <a
          href="https://calendly.com/thepolacydevs"
          target="_blank"
          rel="noreferrer"
          className="relative z-10 h-fit w-fit text-nowrap bg-white px-6 py-2 text-xl font-semibold text-red shadow-[5px_5px_0px_red] transition-all hover:translate-x-[5px] hover:translate-y-[5px] hover:rounded-lg hover:shadow-none lg:text-2xl"
        >
          Get in touch!
        </a>
      </div>
    </div>
  );
}

export default CallToActionSection;
